import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppcloudCountsService } from 'projects/appcloud-admin-portal/src/app/services/appcloud-counts.service';
import { SpinnerService } from 'projects/internal/r1-component-library/src/lib/services';
import { Breakpoints, BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MetricsCard } from 'projects/appcloud-admin-portal/src/app/models/metrics-card.model';
import { AppConstants } from 'projects/appcloud-admin-portal/src/app/app.constants';

@Component({
    selector: 'r1-metrics-dashboard',
    templateUrl: './metrics-dashboard.component.html',
    styleUrls: ['./metrics-dashboard.component.scss'],
    standalone: false
})
export class MetricsDashboardComponent implements OnInit {
    constructor(
        private breakpointObserver: BreakpointObserver,
        private appcloudCountsService: AppcloudCountsService,
        private spinnerService: SpinnerService
    ) {}
    isLoading$: Observable<boolean> = this.spinnerService.isLoading();
    loadingMessages$: Observable<string[]> = this.spinnerService.getMessages();

    public cards: MetricsCard[];
    // Used despite no reference in code
    // https://material.angular.io/cdk/layout/overview#predefined-breakpoints
    private cardsObserver = this.breakpointObserver
        .observe([
            Breakpoints.HandsetPortrait,
            Breakpoints.HandsetLandscape,
            Breakpoints.TabletPortrait,
            Breakpoints.TabletLandscape,
            Breakpoints.WebPortrait,
            Breakpoints.Large,
            Breakpoints.XLarge
        ])
        .subscribe((breakpoint: BreakpointState) => {
            let bps = breakpoint.breakpoints;

            if (bps[Breakpoints.HandsetPortrait]) {
                // Phones
                this.setMetricsColumn(4);
            } else if (bps[Breakpoints.HandsetLandscape]) {
                // Phones
                this.setMetricsColumn(2);
            } else if (
                // Tablets
                bps[Breakpoints.TabletPortrait] ||
                bps[Breakpoints.WebPortrait] ||
                bps[Breakpoints.TabletLandscape]
            ) {
                this.setMetricsColumn(2);
            } else if (bps[Breakpoints.Large] || bps[Breakpoints.XLarge]) {
                // Monitors/PCs
                this.setMetricsColumn(1);
            } else {
                this.setMetricsColumn(4);
            }
        });

    public setMetricsColumn(columnSize: number) {
        const newmetrics: MetricsCard[] = AppConstants.metricsDefaultDisplayConfiguration;
        newmetrics.map((metricsCard) => (metricsCard.cols = columnSize));
        this.cards = newmetrics;
    }

    ngOnInit() {
        this.appcloudCountsService.getMetricsCards().subscribe((cards) => (this.cards = cards));
    }
}
