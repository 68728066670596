import { Component } from '@angular/core';

@Component({
    selector: 'r1-login-welcome',
    template: `
        <div id="welcome-body">
            <h1>Welcome to the <br />Relationship One AppCloud</h1>
            <h2>Extending your Oracle Marketing stack.</h2>
            <p id="announcement" *ngIf="announcement"><b>Announcement:</b> {{ announcement }}</p>
        </div>
    `,
    styles: [
        `
                    #welcome-body {
                        padding: 2rem;
                    }

                    h1 {
                        font-size: var(--r1-font-36);
                        margin-top: 0;
                    }

                    #announcement {
                        color: var(--r1-smoke);
                        margin-top: 4rem;
                        margin-bottom: 0px;
                        font-size: var(--r1-font-14);
                    }
                `
    ],
    standalone: false
})
export class LoginWelcomeComponent {
    constructor() {}

    announcement: string = '';
}
