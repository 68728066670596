<r1-lib-field-row [fieldRowWidth]="5" [formGroup]="transformationFormGroup">
    <r1-field-matfield
        label="Data Transformation Type"
        [control]="transformationFormGroup.controls.fieldTransformationType"
        [fieldWidth]="2"
    >
        <mat-select formControlName="fieldTransformationType">
            <mat-option [value]="'date'">Date</mat-option>
        </mat-select>
    </r1-field-matfield>
</r1-lib-field-row>

<r1-lib-field-row [fieldRowWidth]="9" [formGroup]="transformationFormGroup">
    <r1-field-misc
        [fieldWidth]="2"
    >
        <mat-button-toggle-group
            formControlName="hasCustomSourceFormat"
            (change)="changeSourceFormat($event, transformationFormGroup)"
        >
            <mat-button-toggle
                [value]="false"
                [ngClass]="!transformationFormGroup.controls.hasCustomSourceFormat.value ? 'button-toggled' : 'not-toggled'"
                >Standard</mat-button-toggle
            >
            <mat-button-toggle
                [value]="true"
                [ngClass]="transformationFormGroup.controls.hasCustomSourceFormat.value ? 'button-toggled' : 'not-toggled'"
                [disabled]="!customFormattingTogglesEnabled.source"
                >Custom</mat-button-toggle
            >
        </mat-button-toggle-group>
    </r1-field-misc>
    <r1-field-filler-div [fieldWidth]="1"></r1-field-filler-div>

    <r1-field-matfield
        *ngIf="!transformationFormGroup.controls.hasCustomSourceFormat.value"
        [label]="'Source Format'"
        [control]="transformationFormGroup.controls.sourceFormatString"
        [fieldWidth]="6"
    >
        <mat-select formControlName="sourceFormatString" (openedChange)="generateDateFormatOptions('source')">
            <mat-option *ngFor="let option of sourceFormatOptions; let i = index" [value]="option.value" [attr.data-testId]="'sourceOption'+i">
                {{ option.name }}
            </mat-option>
        </mat-select>
    </r1-field-matfield>

    <r1-field-matfield
        *ngIf="transformationFormGroup.controls.hasCustomSourceFormat.value"
        [label]="'Source Format'"
        [control]="transformationFormGroup.controls.sourceFormatString"
        [fieldWidth]="6"
    >
        <input matInput formControlName="sourceFormatString" />
    </r1-field-matfield>
</r1-lib-field-row>

<r1-lib-field-row [fieldRowWidth]="9" [formGroup]="transformationFormGroup">
    <r1-field-misc [fieldWidth]="2">
        <mat-button-toggle-group [matTooltip]="!customFormattingTogglesEnabled.output ? 'Unity does not support custom output formatting' : null">
            <mat-button-toggle class="button-toggled">Standard</mat-button-toggle>
            <mat-button-toggle [disabled]="!customFormattingTogglesEnabled.output">Custom</mat-button-toggle>
        </mat-button-toggle-group>
    </r1-field-misc>
    <r1-field-filler-div [fieldWidth]="1"></r1-field-filler-div>
    <r1-field-matfield label="Output Format" [control]="transformationFormGroup.controls.outputFormatString" [fieldWidth]="6">
        <mat-select formControlName="outputFormatString" (openedChange)="generateDateFormatOptions('output')">
            <mat-option *ngFor="let option of outputFormatOptions; let i = index" [value]="option.value" [attr.data-testId]="'outputOption'+i">
                {{ option.name }}
            </mat-option>
        </mat-select>
    </r1-field-matfield>
</r1-lib-field-row>
