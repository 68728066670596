import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'r1-row-trash',
    imports: [CommonModule, FontAwesomeModule, MatButtonModule],
    template: `
        <div
            class="row-trash"
        >
            <button
                class="trash-button"
                mat-icon-button
                (click)="removeRow(index)"
                matTooltip="Remove row"
                aria-label="Remove row"
            >
                <fa-icon icon="trash-alt"></fa-icon>
            </button>
        </div>
    `,
    styles: [
        `
                    .trash-button {
                        border: none;
                        margin: 0 8px;
                        cursor: pointer;
                    }
        
                    .trash-button:hover {
                        background-color: transparent;
                    }
                `
    ]
})
export class RowTrashComponent {
    @Input()
    index!: number;

    @Output()
    onRemoveRow: EventEmitter<number> = new EventEmitter<number>();

    constructor(private library: FaIconLibrary) {
        library.addIcons(faTrashAlt);
    }

    removeRow(index: number) {
        this.onRemoveRow.emit(index);
    }
}
