<div id="form-body">
    <form [formGroup]="loginFormGroup" (ngSubmit)="onSubmit()">
        <div id="form">
            <img src="assets/R1_logo.svg" id="logo" />

            <h2 class="gray-label" id="sign-in-header">Sign In</h2>
            <mat-error *ngIf="errorMessage" id="auth-error" [ngClass]="{ 'failed-twice': secondFailedInvalidCreds }">
                {{ errorMessage }}
            </mat-error>

            <mat-form-field appearance="outline">
                <mat-label>Email Address</mat-label>
                <input matInput formControlName="username" required class="input" />
                <mat-error *ngIf="showError('username', 'required')">Email required</mat-error>
                <mat-error *ngIf="showError('username', 'email')">Invalid email</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password" formControlName="password" required class="input" />
                <mat-error *ngIf="showError('password', 'required')">Password required</mat-error>
            </mat-form-field>

            <r1-password-forget-remember [formGroup]="loginFormGroup"></r1-password-forget-remember>
        </div>

        <div id="sign-in-btn">
            <button mat-raised-button color="primary">Sign In</button>
        </div>
    </form>
</div>
