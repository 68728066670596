import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldRowComponent } from '../field-row/field-row.component';
import { SingleField } from '../single-field.model';

@Component({
    selector: 'r1-field-filler-div',
    imports: [CommonModule],
    template: ` <div class="filler"></div> `,
    styles: [
        `
                    .filler {
                        display: inline-block;
                        width: 100%;
                        vertical-align: middle;
                    }
                `
    ]
})
export class FieldFillerDivComponent implements OnInit, SingleField {
    @Input() public fieldWidth!: number;

    /*** Set in @see {@link FieldRowComponent} */
    @HostBinding('style.width') widthPer: string;

    public setWidthPer(width: string) {
        this.widthPer = width;
    }

    constructor() {}

    ngOnInit(): void {
    }
}
