import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PatchAccountsForm } from 'projects/appcloud-admin-portal/src/app/models/patch-accounts-form.model';
import { Account } from 'projects/internal/r1-component-library/src/lib/components/appcloud-login/appcloud-credentials/appcloud-credentials.model';

/**
 * Displays a form to edit an individual App Cloud account.
 * Created with paginated App Cloud admin portal table of accounts in mind.
 */
@Component({
    selector: 'r1-edit-account',
    templateUrl: './edit-account.component.html',
    styleUrls: ['./edit-account.component.scss'],
    standalone: false
})
export class EditAccountComponent implements OnInit {
    @Input() account!: Account;
    @Output() patchAccountValue = new EventEmitter<Account>();
    patchAccountsFormGroup!: FormGroup<PatchAccountsForm>;

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.patchAccountsFormGroup = this.formBuilder.group(new PatchAccountsForm());
        this.patchAccountsFormGroup.patchValue({
            name: this.account.name,
            description: this.account.description,
            sfAccountId: this.account.sfAccountId
        });
    }

    onSubmit(): void {
        if (this.patchAccountsFormGroup.valid) {
            const { name, description, sfAccountId } = this.patchAccountsFormGroup.getRawValue();
            this.patchAccountValue.emit({
                id: this.account.id,
                name: name,
                description: description,
                sfAccountId: sfAccountId
            });
        }
    }

    public showError = (control: string, error: string) => {
        if (this.patchAccountsFormGroup) {
            const formControl = this.patchAccountsFormGroup.get(control) as FormControl;
            return formControl.hasError(error);
        }
    };
}
