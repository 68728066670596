import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoginForm } from 'projects/appcloud-admin-portal/src/app/models/login-form.model';

@Component({
    selector: 'r1-password-forget-remember',
    template: `
        <form [formGroup]="formGroup">
            <div>
                <mat-checkbox [formControl]="formGroup.controls.rememberMe" class="bold">Remember Me</mat-checkbox>
                <button mat-button color="primary" class="bold">Forgot Password?</button>
            </div>
        </form>
    `,
    styles: [
        `
                    div {
                        text-align: center;
                    }
        
                    .bold {
                        font-weight: 500;
                    }
                `
    ],
    standalone: false
})
export class PasswordForgetRememberComponent {
    @Input()
    formGroup!: FormGroup<LoginForm>;

    constructor() {}
}
