import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateFormatObject } from '../../../constants/date.constants';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import moment from 'moment'
import { CommonModule } from '@angular/common';
import { FieldRowComponent } from '../field-row/field-row.component';
import { FieldMatFieldComponent } from '../field-types/field-matfield.component';
import { FieldMiscComponent } from '../field-types/field-misc.component';
import { MatSelectModule } from '@angular/material/select';
import { FieldFillerDivComponent } from '../field-types/field-filler-div.component';
import { MatInputModule } from '@angular/material/input';
import { DateFormatOption, TransformationForm } from '../single-field.model';
import { MatTooltipModule } from '@angular/material/tooltip';


@Component({
    selector: 'r1-mapping-transformation',
    // prettier-ignore
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        FieldRowComponent, FieldMatFieldComponent, FieldMiscComponent, FieldFillerDivComponent,
        MatButtonToggleModule, MatSelectModule, MatInputModule, MatTooltipModule
    ],
    templateUrl: './mapping-transformation.component.html',
    styleUrls: ['./mapping-transformation.component.scss']
})
export class MappingTransformationComponent implements OnInit {
    @Input()
    transformationFormGroup!: FormGroup<TransformationForm>;

    @Input()
    sourceFormatObjects!: DateFormatObject[];

    @Input()
    outputFormatObjects!: DateFormatObject[];

    @Input()
    customFormattingTogglesEnabled!: {source: boolean, output: boolean};

    sourceFormatOptions: DateFormatOption[] = [];
    outputFormatOptions: DateFormatOption[] = [];

    constructor() {}

    ngOnInit(): void {
        this.generateDateFormatOptions('source');
        this.generateDateFormatOptions('output');
    }

    changeSourceFormat(change: MatButtonToggleChange, transformationFormGroup: FormGroup<TransformationForm>) {
        transformationFormGroup.controls.sourceFormatString.reset('');
    }

    generateDateFormatOptions(type: 'source' | 'output'): void {
        let optionsList: DateFormatOption[] = [];
        let formatOptions: DateFormatObject[] = [];

        switch (type) {
            case 'source':
                formatOptions = this.sourceFormatObjects;
                break;
            case 'output':
                formatOptions = this.outputFormatObjects;
                break;
        }

        formatOptions.forEach((object) => {
            let option = new DateFormatOption();
            option.name = moment().format(object.momentFormat) + ' (' + object.displayValue + ')';
            option.value = object.dataValue;
            optionsList.push(option);
        });

        switch (type) {
            case 'source':
                this.sourceFormatOptions = optionsList;
                break;
            case 'output':
                this.outputFormatOptions = optionsList;
                break;
        }
    }
}
