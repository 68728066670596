<h1 mat-dialog-title>Are you sure?</h1>
<div *ngIf="messageContainsHtml" mat-dialog-content [innerHtml]="message">
</div>

<div *ngIf="!messageContainsHtml" mat-dialog-content>
    <p *ngIf="message">
        {{ message }}
    </p>
</div>
<div mat-dialog-actions class="config-change-actions">
    <ng-container [ngSwitch]="leftButton?.type">
        <button *ngSwitchDefault mat-stroked-button (click)="onCancel()">{{ leftButton.text }}</button>
        <button *ngSwitchCase="'raised'" mat-raised-button [color]="leftButton.color" (click)="onCancel()">{{ leftButton.text }}</button>
        <button *ngSwitchCase="'stroked'" mat-stroked-button [color]="leftButton.color" (click)="onCancel()">{{ leftButton.text }}</button>
        <button *ngSwitchCase="'flat'" mat-flat-button [color]="leftButton.color" (click)="onCancel()">{{ leftButton.text }}</button>
        <button *ngSwitchCase="'icon'" mat-icon-button [color]="leftButton.color" (click)="onCancel()">{{ leftButton.text }}</button>
        <button *ngSwitchCase="'fab'" mat-fab [color]="leftButton.color" (click)="onCancel()">{{ leftButton.text }}</button>
        <button *ngSwitchCase="'mini-fab'" mat-mini-fab [color]="leftButton.color" (click)="onCancel()">{{ leftButton.text }}</button>
    </ng-container>
    <ng-container [ngSwitch]="rightButton?.type">
        <button *ngSwitchDefault mat-flat-button class="r1-btn-black" (click)="onConfigChange()">{{ rightButton.text }}</button>
        <button *ngSwitchCase="'raised'" mat-raised-button [color]="rightButton.color" (click)="onConfigChange()">{{ rightButton.text }}</button>
        <button *ngSwitchCase="'stroked'" mat-stroked-button [color]="rightButton.color" (click)="onConfigChange()">{{ rightButton.text }}</button>
        <button *ngSwitchCase="'flat'" mat-flat-button [color]="rightButton.color" (click)="onConfigChange()">{{ rightButton.text }}</button>
        <button *ngSwitchCase="'icon'" mat-icon-button [color]="rightButton.color" (click)="onConfigChange()">{{ rightButton.text }}</button>
        <button *ngSwitchCase="'fab'" mat-fab [color]="rightButton.color" (click)="onConfigChange()">{{ rightButton.text }}</button>
        <button *ngSwitchCase="'mini-fab'" mat-mini-fab [color]="rightButton.color" (click)="onConfigChange()">{{ rightButton.text }}</button>
    </ng-container>
</div>
