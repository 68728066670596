export var ngx_chart_data = [
    {
        name: 'Company 1',
        series: [
            {
                name: 'Wed 14',
                value: Math.random() * 5000
            },
            {
                name: 'Thu 15',
                value: Math.random() * 5000
            },
            {
                name: 'Fri 16',
                value: Math.random() * 5000
            },
            {
                name: 'Sat 17',
                value: Math.random() * 5000
            },
            {
                name: 'Sun 18',
                value: Math.random() * 5000
            },
            {
                name: 'Mon 19',
                value: Math.random() * 5000
            },
            {
                name: 'Tue 20',
                value: Math.random() * 5000
            },
            {
                name: 'Wed 21',
                value: Math.random() * 5000
            },
            {
                name: 'Thu 22',
                value: Math.random() * 5000
            },
            {
                name: 'Fri 23',
                value: Math.random() * 5000
            }
        ]
    },

    {
        name: 'Company 2',
        series: [
            {
                name: 'Wed 14',
                value: Math.random() * 5000
            },
            {
                name: 'Thu 15',
                value: Math.random() * 5000
            },
            {
                name: 'Fri 16',
                value: Math.random() * 5000
            },
            {
                name: 'Sat 17',
                value: Math.random() * 5000
            },
            {
                name: 'Sun 18',
                value: Math.random() * 5000
            },
            {
                name: 'Mon 19',
                value: Math.random() * 5000
            },
            {
                name: 'Tue 20',
                value: Math.random() * 5000
            },
            {
                name: 'Wed 21',
                value: Math.random() * 5000
            },
            {
                name: 'Thu 22',
                value: Math.random() * 5000
            },
            {
                name: 'Fri 23',
                value: Math.random() * 5000
            }
        ]
    },
    {
        name: 'Company 3',
        series: [
            {
                name: 'Wed 14',
                value: Math.random() * 5000
            },
            {
                name: 'Thu 15',
                value: Math.random() * 5000
            },
            {
                name: 'Fri 16',
                value: Math.random() * 5000
            },
            {
                name: 'Sat 17',
                value: Math.random() * 5000
            },
            {
                name: 'Sun 18',
                value: Math.random() * 5000
            },
            {
                name: 'Mon 19',
                value: Math.random() * 5000
            },
            {
                name: 'Tue 20',
                value: Math.random() * 5000
            },
            {
                name: 'Wed 21',
                value: Math.random() * 5000
            },
            {
                name: 'Thu 22',
                value: Math.random() * 5000
            },
            {
                name: 'Fri 23',
                value: Math.random() * 5000
            }
        ]
    }
];
