import { Component, Input, ContentChild, ViewChild, OnChanges, HostBinding } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { ValidationDisplayDirective } from '../validation.directive';
import { SingleField } from '../single-field.model';
import { FieldRowComponent } from '../field-row/field-row.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'r1-field-matfield',
    imports: [CommonModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, ValidationDisplayDirective],
    template: `
        <mat-form-field #materialFormField (focusout)="fireDirectiveOnLeave()" class="row-item" appearance="fill">
            <mat-label>{{ label }}</mat-label>

            <ng-content></ng-content>

            <mat-error>
                <span [myValidationDisplay]="control" [fieldName]="label"></span>
            </mat-error>
        </mat-form-field>
    `,
    styles: [
        `
                    mat-form-field {
                        display: inline-block;
                        width: 100%;
                        vertical-align: middle;
                    }

                    .row-item {
                        padding: 4px 0;
                        background-color: var(--r1-light-grey);
                    }
                `
    ]
})
export class FieldMatFieldComponent implements OnChanges, SingleField {
    @Input() public label!: string;
    @Input() public control!: FormControl;
    @Input() public fieldWidth!: number;

    /*** Set in @see {@link FieldRowComponent} */
    @HostBinding('style.width') widthPer: string;

    // ~~~~DOM manipulation~~~~
    @ContentChild(MatFormFieldControl, { static: true })
    formFieldControl: MatFormFieldControl<any>;

    @ViewChild('materialFormField', { static: true })
    matFormField: MatFormField;

    @ViewChild(ValidationDisplayDirective)
    directive: ValidationDisplayDirective;

    ngOnChanges() {
        this.matFormField._control = this.formFieldControl;

        this.label ?? console.error('Field `label` required in `<r1-field-matfield>`');
        this.control ?? console.error('Field `control` required in `<r1-field-matfield>`');
        this.fieldWidth ?? console.error('Field `fieldWidth` required in `<r1-field-matfield>`');
    }

    public setWidthPer(width: string) {
        this.widthPer = width;
    }

    /**
     * Accounts for leaving a mat-select without picking anything
     */
    fireDirectiveOnLeave() {
        this.control.markAllAsTouched();
        if (this.control.touched && this.control.invalid) {
            this.directive.checkOnTouched();
        }
    }
}
