import { Component } from '@angular/core';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';

@Component({
    selector: 'r1-not-found',
    template: `
        <div class="not-found">
            <h1>404: Page Not Found</h1>
            <h3>Route: {{ currentRoute }}</h3>
        </div>
    `,
    styles: [
        `
                    .not-found {
                        color: red;
                        padding: 1rem;
                        text-align: center;
                    }
                `
    ],
    standalone: false
})
export class NotFoundComponent {
    currentRoute: string = '';

    constructor(private router: Router) {
        this.router.events.subscribe((event: NavigationEvent) => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;
            }
        });
    }
}
