<mat-card appearance="outlined" class="dashboard-card">
    <mat-card-header>
        <mat-card-title>
            <span id="title">{{ readAs }}</span>
            <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
                <mat-icon id="expand-options-icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
                <span *ngIf="card.title != 'MRR'">
                    <button mat-menu-item *ngFor="let option of options" (click)="toggleOption(option)">
                        {{ option.readAs }}
                    </button>
                </span>
                <span *ngIf="card.title == 'MRR'">
                    <button mat-menu-item *ngFor="let option of mrrOptions" (click)="toggleOption(option)">
                        {{ option.readAs }}
                    </button>
                </span>
            </mat-menu>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="dashboard-card-content">
        <ng-container>
            <p class="count" *ngIf="card.count || card.count == 0; else waiting">{{ card.count | number: '1.0-0' }}</p>
            <ng-template #waiting><p class="count">?</p></ng-template>
        </ng-container>
        <p class="about">{{ card.about }}</p>
    </mat-card-content>
</mat-card>
