export class EloquaSyncActionRow {
    id: string;
    syncActionTypeId: number;
    destinationId: number;
    destinationType: string;
    syncActionForEloqua: EloquaSyncAction;
}

export class EloquaSyncAction {
    type: string;
    action: string;
    destination: string;
    status?: string;
}

export class EloquaSyncActionDestination {
    destinationFormat: string;
    id: number;
    type: string;

    constructor(destinationFormat: string, type?: string) {
        this.destinationFormat = destinationFormat;
        this.type = type;
    }

    isContactList(): boolean {
        return this.type === 'ContactList';
    }

    isAccountList(): boolean {
        return this.type === 'AccountList';
    }

    isEmailGroup(): boolean {
        return this.type === 'EmailGroup';
    }
}

export class SyncActionRowEvent {
    rowId: string;
    destinationType: string;

    constructor(rowId: string, destinationType?: string) {
        this.rowId = rowId;
        this.destinationType = destinationType;
    }
}

export class EloquaSyncActionTypes {
    static readonly addToContactList = new EloquaSyncActionTypes('contact', 1, 'Add To Contact List', 'add', new EloquaSyncActionDestination('{{ContactList[<id>]}}', 'ContactList'));
    static readonly removeFromContactList = new EloquaSyncActionTypes('contact', 2, 'Remove From Contact List', 'remove', new EloquaSyncActionDestination('{{ContactList[<id>]}}', 'ContactList'));
    static readonly subscribeToEmailGroup = new EloquaSyncActionTypes('contact', 3, 'Subscribe To Email Group', 'setStatus', new EloquaSyncActionDestination('{{EmailGroup[<id>]}}', 'EmailGroup'), 'subscribed');
    static readonly unsubscribeFromEmailGroup = new EloquaSyncActionTypes('contact', 4, 'Unsubscribe From Email Group', 'setStatus', new EloquaSyncActionDestination('{{EmailGroup[<id>]}}', 'EmailGroup'), 'unsubscribed');
    static readonly globalSubscribe = new EloquaSyncActionTypes('contact', 5, 'Global Subscribe', 'setStatus', new EloquaSyncActionDestination('{{GlobalSubscribe}}'), 'subscribed');
    static readonly globalUnsubscribe = new EloquaSyncActionTypes('contact', 6, 'Global Unsubscribe', 'setStatus', new EloquaSyncActionDestination('{{GlobalSubscribe}}'), 'unsubscribed');
    static readonly addToAccountList = new EloquaSyncActionTypes('account', 7, 'Add To Account List', 'add', new EloquaSyncActionDestination('{{AccountList[<id>]}}', 'AccountList'));
    static readonly removeFromAccountList = new EloquaSyncActionTypes('account', 8, 'Remove From Account List', 'remove', new EloquaSyncActionDestination('{{AccountList[<id>]}}', 'AccountList'));


    private constructor(public readonly type: string,
                        public readonly id: number,
                        public readonly displayName: string,
                        public readonly action: string,
                        public readonly destination: EloquaSyncActionDestination,
                        public readonly status?: string) {}

    public static getContactAndAccountTypes(): EloquaSyncActionTypes[] {
        return EloquaSyncActionTypes.getContactTypes().concat(EloquaSyncActionTypes.getAccountTypes());
    }

    public static getContactTypes(): EloquaSyncActionTypes[] {
        return [
            EloquaSyncActionTypes.addToContactList,
            EloquaSyncActionTypes.removeFromContactList,
            EloquaSyncActionTypes.subscribeToEmailGroup,
            EloquaSyncActionTypes.unsubscribeFromEmailGroup,
            EloquaSyncActionTypes.globalSubscribe,
            EloquaSyncActionTypes.globalUnsubscribe
        ];
    }

    public static getAccountTypes(): EloquaSyncActionTypes[] {
        return [
            EloquaSyncActionTypes.addToAccountList,
            EloquaSyncActionTypes.removeFromAccountList
        ];
    }

    public hasContactListDestination(): boolean {
        return this.destination.isContactList();
    }

    public hasAccountListDestination(): boolean {
        return this.destination.isAccountList();
    }

    public hasEmailGroupDestination(): boolean {
        return this.destination.isEmailGroup();
    }
}