import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { ValidationDisplayDirective } from '../validation.directive';
import { SingleField } from '../single-field.model';
import { FieldRowComponent } from '../field-row/field-row.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'r1-field-misc',
    imports: [CommonModule],
    template: `
        <div class="row-item">
            <ng-content></ng-content>
        </div>
    `,
    styles: [
        `
                    .row-item {
                        display: inline-block;
                        width: 100%;
                        vertical-align: middle;
                        padding: 4px 0;
                        background-color: var(--r1-light-grey);
                    }
                `
    ]
})
export class FieldMiscComponent implements SingleField {
    @Input() public fieldWidth!: number;

    /*** Set in @see {@link FieldRowComponent} */
    @HostBinding('style.width') widthPer: string;

    @ViewChild(ValidationDisplayDirective)
    directive: ValidationDisplayDirective;

    public setWidthPer(width: string) {
        this.widthPer = width;
    }
}
