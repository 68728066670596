// Core Angular + misc modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppcloudAdminRoutingModule } from '../appcloud-admin-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Material modules
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

// Models
// import { NavLink } from '../../../models/nav-link.model';
import { MetricsCard } from '../../../models/metrics-card.model';
import { NgxChartsModule } from '@swimlane/ngx-charts';

// In-project components
import { HomeComponent } from './home.component';
import { HeaderComponent } from './header/header.component';
import { MetricsDashboardComponent } from './metrics-dashboard/metrics-dashboard.component';
import { MetricsChartDatapointComponent } from './metrics-dashboard/metrics-chart-datapoint/metrics-chart-datapoint.component';
import { MetricsSingleDatapointComponent } from './metrics-dashboard/metrics-single-datapoint/metrics-single-datapoint.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FooterComponent } from './footer/footer.component';

// Out-of-project shared modules
import { HttpErrorSnackBarComponent } from 'projects/internal/r1-component-library/src/lib/components/snack-bars/http-error/http-error-snack-bar.component';
import { R1ComponentLibraryModule } from 'projects/internal/r1-component-library/src/public_api';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        HomeComponent,
        HeaderComponent,
        UserMenuComponent,
        MetricsDashboardComponent,
        MetricsChartDatapointComponent,
        MetricsSingleDatapointComponent,
        WelcomeComponent,
        FooterComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        AppcloudAdminRoutingModule,
        FontAwesomeModule,
        R1ComponentLibraryModule,
        NgxChartsModule,
        MatSidenavModule,
        MatListModule,
        MatGridListModule,
        MatChipsModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatTooltipModule
    ],
    exports: [HomeComponent],
    providers: [MetricsCard]
})
export class HomeModule {}
