<div class="page-container">
    <mat-sidenav-container>
        <div id="account-page">
            <div id="page-header">
                <h1>Accounts</h1>
                <button mat-raised-button class="r1-btn-black" [disabled]="true">+ Add Account</button>
            </div>

            <mat-divider id="top-line"></mat-divider>

            <div id="accounts-table">
                <r1-accounts-table (viewAccount)="toggleViewAccountInfo($event)"></r1-accounts-table>
            </div>
        </div>

        <mat-sidenav [opened]="sidenavOpen" mode="side" position="end" *ngIf="sidenavOpen">
            <ng-container *ngIf="editingSelectedAccount; then editMode; else viewMode"> </ng-container>
            <button mat-raised-button (click)="closeSidenav()" id="close-sidenav">X</button>

            <ng-template #editMode>
                <button mat-raised-button (click)="toggleEditAccount()" class="toggle-edit">Cancel</button>
                <r1-edit-account
                    [account]="selectedAccount"
                    (patchAccountValue)="accountPatched($event)"
                ></r1-edit-account>
            </ng-template>

            <ng-template #viewMode>
                <button mat-raised-button (click)="toggleEditAccount()" class="toggle-edit">Edit</button>
                <r1-view-account [account]="selectedAccount"></r1-view-account>
            </ng-template>
        </mat-sidenav>
    </mat-sidenav-container>
</div>
