import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from '@angular/router';

import { AppcloudAdminComponent } from './appcloud-admin.component';
import { MetricsDashboardComponent } from './home/metrics-dashboard/metrics-dashboard.component';
import { AccountsListComponent } from './tools/accounts-list/accounts-list.component';
import { NotFoundComponent } from '../not-found/not-found.component';
import { AuthenticationService } from 'projects/internal/r1-component-library/src/lib/services/appcloud';

function isAuthorizedGuard(): CanActivateFn {
    return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const authService = inject(AuthenticationService);
        const router = inject(Router);
        const url: string = state.url;
    
        let isAuthorized = authService.isAuthorized();
        if (isAuthorized) {
            return true;
        }
        authService.redirectUrl = url;
        return router.parseUrl('/login');
    }
}

function isLoggedInGuard(): CanActivateFn {
    return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const authService = inject(AuthenticationService);
        const router = inject(Router);
        const url: string = state.url;
    
        let isLoggedIn = authService.isAuthenticated();
        if (isLoggedIn) {
            return true;
        }
        authService.redirectUrl = url;
        return router.parseUrl('/login');
    }
}

const routes: Routes = [
    {
        path: 'admin',
        component: AppcloudAdminComponent,
        canActivate: [isLoggedInGuard(), isAuthorizedGuard()],
        canActivateChild: [isLoggedInGuard(), isAuthorizedGuard()],
        children: [
            { path: 'metrics', component: MetricsDashboardComponent },
            {
                path: 'tools',
                children: [{ path: 'accounts', component: AccountsListComponent }]
            },
            {
                path: '**',
                component: NotFoundComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AppcloudAdminRoutingModule {}
