import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

/**
 *
 */
export const VALIDATION_MESSAGES = {
    required: 'Please enter {{fieldName}}.',
    email: 'Must be a valid email',
    minlength: '{{fieldName}} must be greater than {{requiredLength}}. Received value was {{actualLength}}'
};

export const NGX_VALIDATION_MESSAGES_KEY = 'VALIDATION_MESSAGES_INJECTION_KEY';

@Directive({
    selector: '[myValidationDisplay]',
    standalone: true
})
export class ValidationDisplayDirective implements OnInit, OnDestroy {
    @Input('myValidationDisplay') public control: UntypedFormControl;
    @Input() public fieldName: string;

    public errorMessage$: Subscription;

    constructor(
        private element: ElementRef,
        @Inject(NGX_VALIDATION_MESSAGES_KEY) private messages = VALIDATION_MESSAGES
    ) {}

    public ngOnInit(): void {
        if (!this.control) {
            return;
        }

        this.errorMessage$ = this.control.valueChanges
            .pipe(
                debounceTime(100),
                map(() => {
                    const { dirty, invalid, touched } = this.control;
                    return (dirty || touched) && invalid ? this.getErrorMessage() : '';
                })
            )
            .subscribe((message) => {
                this.element.nativeElement.innerText = message || '';
            });
    }

    public ngOnDestroy(): void {
        if (this.errorMessage$) {
            this.errorMessage$.unsubscribe();
        }
    }

    /**
     * @description For external calls in cases such as leaving a `mat-select` field without selecting
     */
    public checkOnTouched(): void {
        let show = this.showError(this.control);
        let message = show ? this.getErrorMessage() : '';
        this.element.nativeElement.innerText = message || '';
    }

    /**
     * @returns {string} Formatted error message for end user
     * @description Returns the end user's error message after the error has been matched to a validation message and interpolated
     */
    private getErrorMessage(): string {
        const {
            control: { errors, value },
            fieldName,
            messages
        } = this;

        if (!errors) {
            return;
        }

        for (const key in messages) {
            if (!this.deepCheck(errors, key)) {
                continue;
            }

            const message = messages[key];

            if (!messages[key]) {
                return `No message found for ${key} validator.`;
            }

            const messageData = {
                ...errors[key],
                value,
                fieldName
            };

            return this.formatString(message, messageData);
        }

        throw new Error(
            'No Error Message Found in the dictionary of error messages. Check the spelling of the error and its parameters.'
        );
    }

    /**
     * @param {object} obj The error object
     * @param {string} prop Property name as string to match to a field name in the object
     * @returns {boolean} Error has that property or not
     * @description Recursively looks for a matching property at some level of the validation error object
     */
    private deepCheck(obj: object, prop: string): boolean {
        if (typeof obj === 'object' && obj !== null) {
            if (obj.hasOwnProperty(prop)) {
                return true;
            }
            for (const p in obj) {
                if (obj.hasOwnProperty(p) && this.deepCheck(obj[p], prop)) {
                    return true;
                }
            }
        }
        return false;
    }

    private showError(formControl: UntypedFormControl): boolean {
        return !formControl.valid && (formControl.touched || formControl.dirty);
    }

    /**
     *
     * @param {string} template
     * @param {any} data
     * @returns {string} End user's error message
     * @description Interpolates the error message template to enduser format
     */
    private formatString(template: string, data: any = {}): string {
        Object.keys(data).forEach((key) => {
            template = template.replace(new RegExp('{{' + key + '}}', 'g'), data[key]);
        });

        return template;
    }
}
