import { Component, OnInit, Input } from '@angular/core';
import { SpinnerService } from '../../services/spinner/spinner.service'; // being used for JSDoc reference

/**
 * Used in conjunction with `SpinnerService`
 * @see {@link SpinnerService}
 */
@Component({
    selector: 'r1-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
    standalone: false
})
export class LoadingSpinnerComponent implements OnInit {
    @Input()
    loadingMessages: string[];

    constructor() {}

    ngOnInit() {}
}
