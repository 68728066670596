import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RowMultipleOptionsComponent } from '../row-action/row-multiple-options.component';
import { RowTrashComponent } from '../row-action/row-trash.component';
import { RowOptionButtons, RowOptionChoice } from '../single-field.model';

@Component({
    selector: 'r1-lib-field-mapping',
    imports: [CommonModule, MatButtonModule, RowTrashComponent, RowMultipleOptionsComponent],
    template: `
        <div class="mapping-row" [ngStyle]="{ 'align-items': rowOptionButtons?.length === 1 ? 'center' : 'flex-start' }">
            <div class="row-form" [ngClass]="index < totalRequiredRows ? 'required-row' : 'optional-row'">
                <ng-content></ng-content>
            </div>

            <!-- Creates the approximate size of the delete and multi option row -->
            <div *ngIf="rowOptionButtons?.length === 0" style="visibility: hidden">
                <button mat-icon-button></button>
            </div>

            <ng-container *ngIf="rowOptionButtons?.length === 1">
                <r1-row-trash
                    *ngIf="rowOptionButtons[0].value === 'delete'; else defaultLengthOne"
                    [index]="index"
                    (onRemoveRow)="removeRow($event)"
                    [ngStyle]="{
                        visibility: index + 1 > totalRequiredRows ? 'visible' : 'hidden'
                    }"
                ></r1-row-trash>
                <ng-template #defaultLengthOne>
                    <div style="visibility: hidden">
                        <button mat-icon-button></button>
                    </div>
                </ng-template>
            </ng-container>

            <r1-row-multiple-options
                *ngIf="rowOptionButtons?.length > 1"
                [rowOptions]="rowOptionButtons"
                [index]="index"
                [totalRequiredRows]="totalRequiredRows"
                (clickOption)="selectOptionOfMultiple($event)"
            ></r1-row-multiple-options>
        </div>
    `,
    styleUrls: ['./field-mapping.component.scss']
})
export class FieldMappingComponent implements OnInit {

    @Input()
    index!: number;

    @Input()
    totalRequiredRows!: number;

    @Input()
    rowOptionButtons!: RowOptionButtons[];

    @Output()
    clickOption: EventEmitter<RowOptionChoice> = new EventEmitter<RowOptionChoice>();

    ngOnInit(): void {
        this.index ?? console.error('Field `index` required in `<r1-lib-field-mapping>`');
        this.totalRequiredRows ?? console.error('Field `totalRequiredRows` required in `<r1-lib-field-mapping>`');
        this.rowOptionButtons ?? console.error('Field `rowOptionButtons` required in `<r1-lib-field-mapping>`');
    }

    protected removeRow(index: number) {
        this.clickOption.emit({index: index, ...this.rowOptionButtons[index]});
    }

    protected selectOptionOfMultiple(option: RowOptionChoice) {
        this.clickOption.emit({index: this.index, ...option});
    }
}
