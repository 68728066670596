import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { NameCountUtilsService } from 'projects/internal/r1-component-library/src/lib/services/appcloud/name-count-util.service';
import { CloudUserService } from 'projects/internal/r1-component-library/src/lib/services/appcloud/cloud-user.service';
import { AccountsService } from 'projects/internal/r1-component-library/src/lib/services/appcloud';
import { AppInstanceService } from 'projects/internal/r1-component-library/src/lib/services/appcloud/app-instance.service';

import { SpinnerService } from 'projects/internal/r1-component-library/src/lib/services/spinner/spinner.service';
import { ErrorHandlingService } from 'projects/internal/r1-component-library/src/lib/services/error-handling';

import { MetricsCard } from '../models/metrics-card.model';
import moment from 'moment';
import { AppConstants } from '../app.constants';

class Aggregate {
    aggregate: number;
}

@Injectable({
    providedIn: 'root'
})
export class AppcloudCountsService {
    constructor(
        private nameCountUtilsService: NameCountUtilsService,
        private cloudUserService: CloudUserService,
        private cloudAccountService: AccountsService,
        private appInstanceService: AppInstanceService,

        private spinnerService: SpinnerService,
        private errorHandlingService: ErrorHandlingService,
        private http: HttpClient
    ) {}

    public cards: MetricsCard[] = [];
    public today: string = new Date().toISOString();

    public daysAgo30 = moment().utc().subtract(30, 'days').toISOString();
    public daysAgo60 = moment().utc().subtract(60, 'days').toISOString();
    public daysAgo90 = moment().utc().subtract(90, 'days').toISOString();

    public previousMonth = moment().utc().startOf('month').subtract(1, 'months').toISOString();
    public endOfPreviousMonth = moment().utc().endOf('month').subtract(1, 'months').toISOString();

    public startOfCurrentMonth = moment().utc().startOf('month').toISOString();
    public endOfCurrentMonth = moment().utc().endOf('month').toISOString();

    getMetricsCards(): Observable<MetricsCard[]> {
        this.getUserCounts(this.today, '<=', 'regexMatch', '^(?!.*relationshipone.com)');
        this.getAccountCounts(this.today, '<=', 'ownedByR1', 'false');
        this.getAppInstanceCounts(this.today, '<=', 'ownedByR1', 'false');
        this.getTotalMRR(this.startOfCurrentMonth, this.endOfCurrentMonth);

        // This part is setting names of the cards and stuff like default number of columns
        this.cards = AppConstants.metricsDefaultDisplayConfiguration;

        // Whereas this is finalizing it with the counts added from the above service calls
        const cards = of(this.cards);
        return cards;
    }

    getUserCounts(createdAtdate: string, operator: string, ownershipOperator: string, ownershipValue: string) {
        let countResponse: number = null;
        this.cloudUserService.getCloudUserCount(createdAtdate, operator, ownershipOperator, ownershipValue).subscribe(
            (countResponseRes) => {
                countResponse = countResponseRes[0].count;
                this.cards[0].count = countResponse;
            },
            (err) => {
                console.log(err);
            }
        );
    }

    getAccountCounts(createdAtdate: string, operator: string, ownershipOperator: string, ownershipValue: string) {
        let countResponse: number = null;
        this.cloudAccountService.getAccountsCount(createdAtdate, operator, ownershipOperator, ownershipValue).subscribe(
            (countResponseRes) => {
                countResponse = countResponseRes[0].count;
                this.cards[1].count = countResponse;
            },
            (err) => {
                console.log(err);
            }
        );
    }

    getAppInstanceCounts(createdAtdate: string, operator: string, ownershipOperator: string, ownershipValue: string) {
        let countResponse: number = null;
        this.appInstanceService
            .getAppInstancesCount(createdAtdate, operator, ownershipOperator, ownershipValue)
            .subscribe(
                (countResponseRes) => {
                    countResponse = countResponseRes[0].count;
                    this.cards[2].count = countResponse;
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    // ~~~~~~ MRR specifics ~~~~~~

    getTotalMRR(monthStart: string, monthEnd: string) {
        let countResponse: number = null;
        this.getTotalMRRRequest(monthStart, monthEnd).subscribe(
            (countResponseRes) => {
                countResponse = countResponseRes[0].aggregate;
                this.cards[3].count = countResponse;
            },
            (err) => {
                console.log(err);
            }
        );
    }

    getYTD_MRR() {
        let countResponse: number = null;
        this.getYTD_MRRRequest().subscribe(
            (countResponseRes) => {
                countResponse = countResponseRes
                    .map((aggregateRes) => aggregateRes.aggregate)
                    .reduce((a, b) => a + b, 0);
                this.cards[3].count = countResponse;
            },
            (err) => {
                console.log(err);
            }
        );
    }

    // Broken out seperately because all other `gets` in this service are external, existing observables
    getTotalMRRRequest(monthStart: string, monthEnd: string): Observable<Aggregate[]> {
        this.spinnerService.addProcess('AppcloudCountsService.getTotalMRRRequest', 'Loading Total MRR...');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            })
        };

        let queryUrl = this.nameCountUtilsService.makeMRRQuery(monthStart, monthEnd);

        return this.http.get<Aggregate[]>(queryUrl, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while retrieving your cloud user info. Please try again later.'
                );
                return of<Aggregate[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('AppcloudCountsService.getTotalMRRRequest'))
        );
    }

    getYTD_MRRRequest(): Observable<Aggregate[]> {
        this.spinnerService.addProcess('AppcloudCountsService.getYTD_MRRRequest', 'Loading YTD revenue...');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            })
        };

        let queryUrl = this.nameCountUtilsService.makeYTD_MRRQuery();

        return this.http.get<Aggregate[]>(queryUrl, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while retrieving your cloud user info. Please try again later.'
                );
                return of<Aggregate[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('AppcloudCountsService.getYTD_MRRRequest'))
        );
    }
}
