import { Component, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

/**
 * @description This is an often repeated patten in apps; 30% width instructions and 70% content.
 *              The intent of this is mainly that FlexLayout is deprecated from ng15 and on,
 *              so components wrapped like this if we need to migrate away can be changed at once.
 *
 *              Note: the `auto` space tends to be where `background-color: var(--r1-light-gray);
 *                    padding: 20px;` styles are applied to some class. Due to the way that Flex Layout works,
 *                    it was not worth baking into this component. Apply those classes to the div that
 *                    the `[content]` selector does, ex) `<div content class="actions">`
 *
 * @example
 * <r1-flex-row-instructions-and-content-container [formGroup]="...">
 *      <r1-instructions instructions [headerText]="'...'">
 *          ...
 *      </r1-instructions>
 *
 *      <!-- Apply the class on the `content` selector -->
 *      <div content class="padded-light-gray-content">
 *          ...
 *      </div>
 * <r1-flex-row-instructions-and-content-container>
 */
@Component({
    imports: [FlexLayoutModule],
    selector: 'r1-flex-row-instructions-and-content-container',
    template: `
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxFlexFill>
            <div fxFlex.gt-sm="30%">
                <ng-content select="[instructions]"></ng-content>
            </div>
            <div fxFlex="auto">
                <ng-content select="[content]"></ng-content>
            </div>
        </div>
    `,
    styles: []
})
export class FlexInstructionsAndContentContainerComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
