<div class="chart-container" #chartContainer>
    <ngx-charts-bar-vertical-stacked
        [view]="view"
        [scheme]="chartColors"
        [results]="chartDisplayData"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [animations]="animations"
        [xAxisTicks]="xAxisTicks"
    >
    </ngx-charts-bar-vertical-stacked>
</div>
