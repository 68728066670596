import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatSnackBar,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

export interface GenericSnackbarData {
    messages: string[];
    showDismissButton: boolean;
}

@Component({
    selector: 'r1-generic-snackbar',
    templateUrl: './generic-snackbar.component.html',
    styleUrls: ['./generic-snackbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class GenericSnackbarComponent {
    constructor(
        private matSnackBarService: MatSnackBar,
        @Inject(MAT_SNACK_BAR_DATA) public data: GenericSnackbarData
    ) {}

    onDismiss(): void {
        this.matSnackBarService.dismiss();
    }
}
