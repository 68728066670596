import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructionsModule } from '../instructions/instructions.module';
import { AuthorizedEloquaUsersComponent } from './authorized-eloqua-users.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatOptionModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [AuthorizedEloquaUsersComponent],
    imports: [
        CommonModule,
        InstructionsModule,
        FlexLayoutModule,
        MatIconModule,
        MatFormFieldModule,
        MatOptionModule,
        MatChipsModule,
        MatAutocompleteModule,
        ReactiveFormsModule
    ],
    exports: [AuthorizedEloquaUsersComponent]
})
export class AuthorizedEloquaUsersModule {}