import { Component, Input, OnChanges, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { ChartResultElement, View } from '../models/chart-data.model';
import { ReportChartDataService } from './report-chart.data.service';
import { cloneDeep } from 'lodash';
import { Color } from '@swimlane/ngx-charts';

@Component({
    selector: 'r1-report-chart',
    templateUrl: './report-chart.component.html',
    styleUrls: ['./report-chart.component.scss'],
    standalone: false
})
export class ReportChartComponent implements OnChanges, OnInit, AfterViewChecked {
    @Input()
    chartData: ChartResultElement[] = [];

    // Note - We somehow only pass down only a subset of the properties of the color scheme for ngx charts
    //        > scheme: string | Color
    //        Where `Color` has `domain: string[]`.
    //        Example:
    //            chartColors: object = {
    //                domain: [
    //                    ReportingConstants.Colors.brilliantBlue,
    //                ]
    //            };
    @Input()
    chartColors: string[] | Color | any = [];

    @Input()
    chartView: View;

    @ViewChild('chartContainer')
    chartContainer: ElementRef<HTMLElement>;

    chartDisplayData: ChartResultElement[] = [];
    timeIncrementWindows: ChartResultElement[] = [];
    view: [number, number] = [850, 400];
    showXAxis: boolean = true;
    showYAxis: boolean = true;
    showXAxisLabel: boolean = true;
    showYAxisLabel: boolean = true;
    xAxisLabel: string = 'Time';
    yAxisLabel: string = 'Count';
    gradient: boolean = false;
    showLegend: boolean = true;
    animations: boolean = true;
    xAxisTicks: any[] = [];

    constructor(private reportChartDataService: ReportChartDataService) {}

    ngOnInit() {
        if (this.chartView) {
            let chartDisplay = this.reportChartDataService.initializeChart(this.chartView);
            this.chartDisplayData = chartDisplay.data;
            this.timeIncrementWindows = chartDisplay.data;
            this.xAxisTicks = chartDisplay.axisMarkers;
        }
    }

    ngAfterViewChecked() {
        this.view[0] = this.chartContainer.nativeElement.offsetWidth;
    }

    ngOnChanges() {
        if (this.chartView && this.chartData && this.timeIncrementWindows) {
            if (this.chartData.length > 0 && this.timeIncrementWindows.length > 0) {
                let timeIncrementWindows = cloneDeep(this.timeIncrementWindows);
                this.chartDisplayData = this.reportChartDataService.loadChart(this.chartData, timeIncrementWindows);
            } else {
                this.chartDisplayData = this.timeIncrementWindows;
            }
        }
    }
}
