import { FormControl, Validators } from '@angular/forms';
import { ValidationDisplayDirective } from './validation.directive';

export interface SingleField {
    // ~~~~Inputs~~~~
    fieldWidth: number;
    widthPer: string; // Set in FieldRowComponent parent
    control?: FormControl;
    label?: string;

    // ~~~~DOM accessing~~~~
    directive?: ValidationDisplayDirective;

    // ~~~~Methods~~~~
    setWidthPer(width: string): void;
}

export class DateFormatOption {
    name: string;
    value: string;
}

export class TransformationForm {
    fieldTransformationType = new FormControl<string>('date', Validators.required);
    hasCustomSourceFormat = new FormControl<boolean>(false, Validators.required);
    sourceFormatString = new FormControl<string>('', Validators.required);
    outputFormatString = new FormControl<string>('', Validators.required);
}

export class RowOptionButtons {
    value: 'delete' | 'enableTransform' | 'disableTransform' | (string & {});
    display: string;
    show?: boolean;
}

export class RowOptionChoice extends RowOptionButtons {
    index: number;
}