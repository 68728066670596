import { Injectable } from '@angular/core';
import { ChartSeries, ChartResultElement, ChartDisplay, View } from '../models/chart-data.model';
import moment from 'moment'


@Injectable({
    providedIn: 'root'
})
export class ReportChartDataService {
    chartDisplayResult: ChartDisplay = new ChartDisplay();

    initializeChart(view: View): ChartDisplay {
        return this.generateNewTimeIncrementWindows(view);
    }

    loadChart(reportData: ChartResultElement[], timeIncrementWindows: ChartResultElement[]): ChartResultElement[] {
       return this.mergeDataIntoWindows(reportData, timeIncrementWindows);
    }

    private generateNewTimeIncrementWindows(view: View): ChartDisplay {
        let chartDisplay: ChartDisplay = new ChartDisplay();
        let chartDisplayData: ChartResultElement[] = [];
        let axisMarkers: string[] = [];
        let numWindows = view.numberOfUnits * view.incrementsPerUnit;
        let i;
        let start = this.generateStartTime();
        for (i = 0; i < numWindows; i++) {
            chartDisplayData.push(this.generateChartDataObj(start));
            if (i % view.incrementsPerUnit === 0) {
                axisMarkers.push(moment(start).format('HH:mm'));
            }
            start = moment(start)
                .add(view.incrementSize, view.incrementUnit)
                .format();
        }
        chartDisplay.data = chartDisplayData;
        chartDisplay.axisMarkers = axisMarkers;
        return chartDisplay;
    }

    private mergeDataIntoWindows(
        reportData: ChartResultElement[],
        timeWindows: ChartResultElement[]
    ): ChartResultElement[] {
        let clonedTimeWindows = [...timeWindows];
        reportData.forEach(chartElement => {
            let chartElementTime = moment(chartElement.name).format('HH:mm');
            clonedTimeWindows.forEach((window, i) => {
                if (i === timeWindows.length - 1) {
                    if (chartElementTime >= timeWindows[i].name) {
                        this.modifyTimeWindow(window, chartElement);
                    }
                } else {
                    if (chartElementTime >= timeWindows[i].name && chartElementTime < timeWindows[i + 1].name) {
                        this.modifyTimeWindow(window, chartElement);
                    }
                }
            });
        });
        return clonedTimeWindows;
    }

    private generateStartTime() {
        return moment(new Date())
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .format();
    }

    private generateChartDataObj(startTime: any): ChartResultElement {
        let newResultObj: ChartResultElement = new ChartResultElement();
        newResultObj.name = moment(startTime).format('HH:mm');
        newResultObj.series = [];
        return newResultObj;
    }

    private modifyTimeWindow(window: ChartResultElement, chartElement: ChartResultElement) {
        if (window.series.length === 0) {
            window.series = chartElement.series;
        } else {
            window.series = this.mergeSeriesData(chartElement.series, window.series);
        }
    }

    private mergeSeriesData(newSeries: ChartSeries[], existingSeries: ChartSeries[]): ChartSeries[] {
        existingSeries.forEach(seriesElement => {
            let newSeriesMatch = newSeries.find(element => element.name === seriesElement.name);
            seriesElement.value = seriesElement.value + newSeriesMatch.value;
        });
        return existingSeries;
    }
}
