import { NgModule } from '@angular/core';
import { FieldRowComponent } from './field-row/field-row.component';
import { FieldMappingComponent } from './field-mapping/field-mapping.component';
import { FieldMiscComponent } from './field-types/field-misc.component';
import { FieldMatFieldComponent } from './field-types/field-matfield.component';
import { NGX_VALIDATION_MESSAGES_KEY, ValidationDisplayDirective, VALIDATION_MESSAGES } from './validation.directive';
import { RowTrashComponent } from './row-action/row-trash.component';
import { RowMultipleOptionsComponent } from './row-action/row-multiple-options.component';
import { FieldFillerDivComponent } from './field-types/field-filler-div.component';
import { MappingTransformationComponent } from './mapping-transformation/mapping-transformation.component';

@NgModule({
    declarations: [
    ],
    imports: [
        FieldFillerDivComponent,
        FieldMappingComponent,
        FieldMatFieldComponent,
        FieldMiscComponent,
        FieldRowComponent,
        MappingTransformationComponent,
        RowMultipleOptionsComponent,
        RowTrashComponent,
        ValidationDisplayDirective,
    ],
    exports: [
        FieldFillerDivComponent,
        FieldMappingComponent,
        FieldMatFieldComponent,
        FieldMiscComponent,
        FieldRowComponent,
        MappingTransformationComponent,
        ValidationDisplayDirective
    ],
    providers: [
        {
            provide: NGX_VALIDATION_MESSAGES_KEY,
            useValue: {
                ...VALIDATION_MESSAGES
            }
        }
    ]
})
export class FieldModule {}
