// Core Angular + misc modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// In-project components
import { AppCloudLoginComponent } from '../appcloud-login/appcloud-login.component';
import { LoginWelcomeComponent } from '../appcloud-login/login-welcome/login-welcome.component';
import { LoginFormComponent } from '../appcloud-login/login-form/login-form.component';
import { PasswordForgetRememberComponent } from '../appcloud-login/login-form/password-forget-remember/password-forget-remember.component';

// Material modules
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
// import { MatButtonModule, MatFormFieldModule, MatInputModule } from '@angular/material';

@NgModule({
    declarations: [AppCloudLoginComponent, LoginWelcomeComponent, LoginFormComponent, PasswordForgetRememberComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule
    ]
})
export class AppcloudLoginModule {}
