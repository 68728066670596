import { AfterContentInit, Component, ContentChildren, Input, OnDestroy, QueryList } from '@angular/core';
import { delay, Subject, takeUntil } from 'rxjs';
import { FieldFillerDivComponent } from '../field-types/field-filler-div.component';
import { FieldMiscComponent } from '../field-types/field-misc.component';
import { SingleFieldService } from '../singe-field.service';
import { FieldMatFieldComponent } from '../field-types/field-matfield.component';

@Component({
    selector: 'r1-lib-field-row',
    standalone: true,
    template: `
        <form>
            <div class="mapper-row" [style.gap]="flexGapSize">
                <ng-content></ng-content>
            </div>
        </form>
    `,
    styles: [
        `
                    .mapper-row {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: wrap;
                    }
                `
    ]
})
export class FieldRowComponent implements AfterContentInit, OnDestroy {
    @Input() fieldRowWidth: number = 1;
    @Input() flexGapSize: string = '20px';

    @ContentChildren(FieldMatFieldComponent) singleFields: QueryList<FieldMatFieldComponent>;

    @ContentChildren(FieldMiscComponent) singleNonMatFields: QueryList<FieldMiscComponent>;

    @ContentChildren(FieldFillerDivComponent) fieldFiller: QueryList<FieldFillerDivComponent>;

    constructor(private singleFieldService: SingleFieldService) {}

    destroyed$: Subject<void> = new Subject<void>();

    ngAfterContentInit() {
        // Without this, the `field.setWidthPer` in the set functions gives an error that the width is undefined before set
        // https://stackoverflow.com/questions/55286309/changing-contentchildren-models-on-querylist-changes
        setTimeout(() => {
            this.setSingleFieldWidths();
            this.setSingleNonMatFieldsWidths();
            this.setFieldFillerWidths();

            this.singleFields.changes.pipe(delay(0), takeUntil(this.destroyed$)).subscribe(() => {
                this.setSingleFieldWidths();
            });

            this.singleNonMatFields.changes.pipe(delay(0), takeUntil(this.destroyed$)).subscribe(() => {
                this.setSingleNonMatFieldsWidths();
            });

            this.fieldFiller.changes.pipe(delay(0), takeUntil(this.destroyed$)).subscribe(() => {
                this.setFieldFillerWidths();
            });
        }, 0);
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    setSingleFieldWidths() {
        this.singleFields.forEach((field: FieldMatFieldComponent) => {
            field.setWidthPer(this.singleFieldService.setGroupingRows(
                this.fieldRowWidth,
                field.fieldWidth,
                this.flexGapSize
            ))
        });
    }

    setSingleNonMatFieldsWidths() {
        this.singleNonMatFields.forEach((field: FieldMiscComponent) => {
            field.setWidthPer(this.singleFieldService.setGroupingRows(
                this.fieldRowWidth,
                field.fieldWidth,
                this.flexGapSize
            ))
        });
    }

    setFieldFillerWidths() {
        this.fieldFiller.forEach((filler: FieldFillerDivComponent) => {
            filler.setWidthPer(this.singleFieldService.setGroupingRows(
                this.fieldRowWidth,
                filler.fieldWidth,
                this.flexGapSize
            ))
        })
    }
}
