import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import {
    GenericSnackbarComponent,
    GenericSnackbarData,
} from '../../components/snack-bars/generic-snackbar/generic-snackbar.component';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlingService {
    constructor(
        private snackBarService: MatSnackBar,
        private spinnerService: SpinnerService
    ) {}

    clearError() {
        this.snackBarService.dismiss();
    }

    handleHttpError(errorEvent: any, handleMultipleMessages: boolean = false, customErrorMessage: string = null): void {
        const openedSnackBarRef: MatSnackBarRef<GenericSnackbarComponent> = this.snackBarService._openedSnackBarRef;
        let errorMessages: string[];
        let newErrorMessage = customErrorMessage ? customErrorMessage : this.buildHttpErrorMessage(errorEvent); // override default error message if needed

        if (handleMultipleMessages) {
            errorMessages = openedSnackBarRef
                ? [...openedSnackBarRef.instance.data.messages, newErrorMessage]
                : [newErrorMessage]; // append all new messages to the existing snackbar
        } else {
            errorMessages = [newErrorMessage]; // set the error message to the new message
        }

        this.setSnackBarData(openedSnackBarRef, errorMessages);
        this.spinnerService.clearProcesses();
    }

    private buildHttpErrorMessage(errorEvent: any): string {
        switch (errorEvent.status) {
            case 403: {
                return '403 - Auth error';
            }
            case 500: {
                return '500 - Internal Server Error';
            }
            default: {
                return 'There was an error retrieving data';
            }
        }
    }

    private setSnackBarData(openedSnackBarRef, messages: string[]): void {
        console.log(this.snackBarService._openedSnackBarRef);
        if (openedSnackBarRef) {
            const ref: MatSnackBarRef<GenericSnackbarComponent> = this.snackBarService._openedSnackBarRef;
            ref.instance.data.messages = messages;
        } else {
            this.snackBarService.openFromComponent<GenericSnackbarComponent, GenericSnackbarData>(
                GenericSnackbarComponent,
                {
                    data: { showDismissButton: true, messages: messages },
                    panelClass: ['generic-snack-bar-warning'],
                    verticalPosition: 'top',
                }
            );
        }
    }
}
