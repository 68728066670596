import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import { DateRange } from '../models/appstats-report.model';
import moment from 'moment'


@Component({
    selector: 'r1-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    standalone: false
})
export class DatePickerComponent implements OnInit {
    placeholder: string = 'Select a Date';
    selectedDate: string;
    constructor(library: FaIconLibrary) {
        library.addIcons(faCalendarAlt);
    }

    @Input()
    preSelectedDate: string;

    @Output()
    dateChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

    ngOnInit() {
        if (this.preSelectedDate) {
            this.selectedDate = moment(this.preSelectedDate).hour(0).minute(0).second(0).millisecond(0).format();
        } else {
            this.selectedDate = moment(new Date()).hour(0).minute(0).second(0).millisecond(0).format();
        }
    }

    onDateChange(date: any) {
        this.selectedDate = moment(date.value).hour(0).minute(0).second(0).millisecond(0).format();
        let newDateRange: DateRange = {
            start: this.getStart(date.value),
            end: this.getEnd(date.value)
        };
        this.dateChange.emit(newDateRange);
    }

    onNextDay() {
        let newSelectedDate = moment(this.selectedDate)
            .add(1, 'days')
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .format();
        let newDateRange: DateRange = {
            start: this.getIncrementedStart(this.selectedDate),
            end: this.getIncrementedEnd(this.selectedDate)
        };
        this.selectedDate = newSelectedDate;
        this.dateChange.emit(newDateRange);
    }

    onPrevDay() {
        let newSelectedDate = moment(this.selectedDate)
            .subtract(1, 'days')
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .format();
        let newDateRange: DateRange = {
            start: this.getDecrementedStart(this.selectedDate),
            end: this.getDecrementedEnd(this.selectedDate)
        };
        this.selectedDate = newSelectedDate;
        this.dateChange.emit(newDateRange);
    }

    private getStart(date: string): string {
        return moment(date).hour(0).minute(0).second(0).millisecond(0).format();
    }

    private getIncrementedStart(date: string): string {
        return moment(date).add(1, 'days').hour(0).minute(0).second(0).millisecond(0).format();
    }

    private getIncrementedEnd(date: string): string {
        return moment(date).add(1, 'days').hour(23).minute(59).second(59).millisecond(999).format();
    }

    private getEnd(date: string): string {
        return moment(date).hour(23).minute(59).second(59).millisecond(999).format();
    }

    private getDecrementedStart(date: string): string {
        return moment(date).subtract(1, 'days').hour(0).minute(0).second(0).millisecond(0).format();
    }

    private getDecrementedEnd(date: string): string {
        return moment(date).subtract(1, 'days').hour(23).minute(59).second(59).millisecond(999).format();
    }
}
