import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, finalize, retry } from 'rxjs/operators';
import { ErrorHandlingService } from '../../services/error-handling';
import { SpinnerService } from '../../services/spinner';
import { HttpHeaderConstants } from '../../services/http-header.constant';
import {
    AppStatsReportCountRequest,
    AppStatsReportRequest,
    AppStatsReportSumRequest
} from '../../models/appcloud/appstats-report-request.model';
import { AllCharacterHttpParamsEncoder } from '../../utils/AllCharacterHttpParamsEncoder';
import { CountResponse } from '../../models/appcloud';
import { DateRange, NavigationData } from '../../components/app-report/models/appstats-report.model';
import moment from 'moment'

import {
    AppStatsSearch,
    SalesforceError,
    EloquaInteractionSearch,
    DatabaseInteractionSearch,
    EloquaSyncRejectSearch,
    SalesforceErrorSearch,
    SyncSearch,
    AppStatsSearchField,
    GoogleInteractionSearch,
    GoogleErrorSearch,
    EloquaInteraction,
    SyncLogRecord,
    SyncRejectRecord,
    DatabaseInteraction,
    GoogleInteraction,
    GoogleError,
    ResponsysInteractionSearch,
    ResponsysInteraction,
    NetsuiteInteractionSearch,
    NetsuiteErrorSearch,
    NetsuiteError,
    NetsuiteInteraction
} from '../../models/appstats';


@Injectable({
    providedIn: 'root'
})
export class AppstatsReportService {
    constructor(
        private http: HttpClient,
        private spinnerService: SpinnerService,
        private errorHandlingService: ErrorHandlingService
    ) {}

    listResults<T>(
        resultsUrl: string,
        appinstanceId: number,
        rangeStart: string,
        rangeEnd: string,
        additionalHeaders: any = {}
    ): Observable<T[]> {
        this.spinnerService.addProcess('ReportService.list.' + appinstanceId, 'Fetching your results.');
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams({
                encoder: new AllCharacterHttpParamsEncoder(),
                fromObject: {
                    appinstanceId: JSON.stringify(appinstanceId),
                    executionStartRangeBegin: rangeStart,
                    executionStartRangeEnd: rangeEnd
                }
            })
        };

        return this.http.get<T[]>(resultsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve reporting history. Please try again later.'
                );
                return of<T[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('ReportService.list.' + appinstanceId))
        );
    }

    listResultsFromSearch<T>(request: AppStatsReportRequest, additionalHeaders: any = {}): Observable<T[]> {
        this.spinnerService.addProcess('ReportService.listResultsFromSearch', 'Fetching your results.');
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams({
                encoder: new AllCharacterHttpParamsEncoder(),
                fromObject: request.search
            })
        };

        return this.http.get<T[]>(request.resultsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve reporting history. Please try again later.'
                );
                return of<T[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('ReportService.listResultsFromSearch'))
        );
    }

    getCounts<T>(request: AppStatsReportCountRequest, additionalHeaders: any = {}): Observable<T[]> {
        this.spinnerService.addProcess('ReportService.getCounts', 'Fetching counts.');
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams({
                encoder: new AllCharacterHttpParamsEncoder(),
                fromObject: request.search
            })
        };

        if (request.groupBy) {
            customHttpOptions.params = customHttpOptions.params.append('groupBy', request.groupBy);
        }

        return this.http.get<T[]>(`${request.resultsUrl}/counts`, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve reporting details. Please try again later.'
                );
                return of<T[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('ReportService.getCounts'))
        );
    }

    getAggregates<T>(request: AppStatsReportSumRequest, additionalHeaders: any = {}): Observable<T[]> {
        this.spinnerService.addProcess('ReportService.getAggregates', 'Fetching report details.');
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams({
                encoder: new AllCharacterHttpParamsEncoder(),
                fromObject: {
                    operation: request.operation,
                    field: request.field
                }
            })
        };

        this.addSearchParams(request.search, customHttpOptions);

        if (request.groupBy) {
            customHttpOptions.params = customHttpOptions.params.append('groupBy', request.groupBy);
        }

        if (request.groupBySecondsRange) {
            customHttpOptions.params = customHttpOptions.params.append(
                'groupBySecondsRange',
                request.groupBySecondsRange.toString()
            );
        }

        if (request.groupBySecondsOffset) {
            customHttpOptions.params = customHttpOptions.params.append(
                'groupBySecondsOffset',
                request.groupBySecondsOffset.toString()
            );
        }

        customHttpOptions.params = customHttpOptions.params.delete('search').delete('resultsUrl');

        return this.http.get<T[]>(`${request.resultsUrl}/aggregates`, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve reporting details. Please try again later.'
                );
                return of<T[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('ReportService.getAggregates'))
        );
    }

    getJoinedAggregates(url: string, search: AppStatsSearch, additionalHeaders: any = {}): Observable<{}[]> {
        this.spinnerService.addProcess('ReportService.getJoinedAggregates', 'Fetching report details.');
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams({
                encoder: new AllCharacterHttpParamsEncoder()
            })
        };

        this.addSearchParams(search, customHttpOptions);

        return this.http.get<{}[]>(`${url}/joined-aggregates`, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve reporting details. Please try again later.'
                );
                return of<{}[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('ReportService.getJoinedAggregates'))
        );
    }

    getInteractions(
        interactionsUrl: string,
        executionId: string,
        objectType?: string,
        eloquaImportId?: string,
        eloquaExportId?: string,
        additionalHeaders: any = {}
    ): Observable<EloquaInteraction[]> {
        this.spinnerService.addProcess('ReportService.getSyncs.' + executionId, 'Fetching your sync data.');
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams({
                fromObject: {
                    appinstanceExecutionId: executionId,
                    objectType: objectType
                }
            })
        };

        if (eloquaImportId) {
            customHttpOptions.params = customHttpOptions.params.append('eloquaImportId', eloquaImportId);
        }

        if (eloquaExportId) {
            customHttpOptions.params = customHttpOptions.params.append('eloquaExportId', eloquaExportId);
        }

        return this.http.get<EloquaInteraction[]>(interactionsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve sync information. Please try again later.'
                );
                return of<EloquaInteraction[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('ReportService.getSyncs.' + executionId))
        );
    }

    getSyncLogs(
        syncLogsUrl: string,
        executionId: string,
        syncId: string,
        additionalHeaders: any = {}
    ): Observable<SyncLogRecord[]> {
        this.spinnerService.addProcess('ReportService.getSyncLogs.', 'Fetching your sync logs.');
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams({
                fromObject: {
                    appinstanceExecutionId: executionId,
                    eloquaSyncId: syncId
                }
            })
        };

        return this.http.get<SyncLogRecord[]>(syncLogsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve sync information. Please try again later.'
                );
                return of<SyncLogRecord[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('ReportService.getSyncLogs.'))
        );
    }

    getSyncRejects(
        syncRejectsUrl: string,
        executionId: string,
        syncId: string,
        statusCode: string,
        additionalHeaders: any = {}
    ): Observable<SyncRejectRecord[]> {
        this.spinnerService.addProcess(
            'ReportService.getSyncRejects.' + executionId,
            'Fetching your sync reject data.'
        );

        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams({
                fromObject: {
                    appinstanceExecutionId: executionId,
                    eloquaSyncId: syncId,
                    ...(statusCode && { statusCode: statusCode }),
                }
            })
        };

        return this.http.get<SyncRejectRecord[]>(syncRejectsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve sync rejects. Please try again later.'
                );
                return of<SyncRejectRecord[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('ReportService.getSyncRejects.' + executionId))
        );
    }

    searchEloquaInteractions(
        interactionsUrl: string,
        eloquaInteractionSearch: EloquaInteractionSearch,
        additionalHeaders: any = {}
    ): Observable<EloquaInteraction[]> {
        this.spinnerService.addProcess(
            'ReportService.searchEloquaInteractions.' + eloquaInteractionSearch.appinstanceExecutionId,
            'Fetching your Eloqua interaction data.'
        );
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams()
        };

        this.addSearchParams(eloquaInteractionSearch, customHttpOptions);

        return this.http.get<EloquaInteraction[]>(interactionsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve Eloqua interaction information. Please try again later.'
                );
                return of<EloquaInteraction[]>();
            }),
            finalize(() =>
                this.spinnerService.completeProcess(
                    'ReportService.searchEloquaInteractions.' + eloquaInteractionSearch.appinstanceExecutionId
                )
            )
        );
    }

    searchResponsysInteractions(
        interactionsUrl: string,
        responsysInteractionSearch: ResponsysInteractionSearch,
        additionalHeaders: any = {}
    ): Observable<ResponsysInteraction[]> {
        const process =
            'ReportService.searchResponsysInteractions.' + responsysInteractionSearch.appinstanceExecutionId;
        this.spinnerService.addProcess(process, 'Fetching your Responsys interaction data.');
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams()
        };

        this.addSearchParams(responsysInteractionSearch, customHttpOptions);

        return this.http.get<ResponsysInteraction[]>(interactionsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve Responsys interaction information. Please try again later.'
                );
                return of<ResponsysInteraction[]>();
            }),
            finalize(() => this.spinnerService.completeProcess(process))
        );
    }

    searchDatabaseInteractions(
        interactionsUrl: string,
        databaseInteractionSearch: DatabaseInteractionSearch,
        additionalHeaders: any = {}
    ): Observable<DatabaseInteraction[]> {
        this.spinnerService.addProcess(
            'ReportService.searchDatabaseInteractions.' + databaseInteractionSearch.appinstanceExecutionId,
            'Fetching your database interaction data.'
        );
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams()
        };

        this.addSearchParams(databaseInteractionSearch, customHttpOptions);

        return this.http.get<DatabaseInteraction[]>(interactionsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve database interaction information. Please try again later.'
                );
                return of<DatabaseInteraction[]>();
            }),
            finalize(() =>
                this.spinnerService.completeProcess(
                    'ReportService.searchDatabaseInteractions.' + databaseInteractionSearch.appinstanceExecutionId
                )
            )
        );
    }

    searchSyncRejects(
        interactionsUrl: string,
        eloquaSyncRejectSearch: EloquaSyncRejectSearch,
        additionalHeaders: any = {}
    ): Observable<SyncRejectRecord[]> {
        this.spinnerService.addProcess(
            'ReportService.searchSyncRejects.' + eloquaSyncRejectSearch.appinstanceExecutionId,
            'Fetching your Eloqua sync reject data.'
        );
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams()
        };

        this.addSearchParams(eloquaSyncRejectSearch, customHttpOptions);

        return this.http.get<SyncRejectRecord[]>(interactionsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve Eloqua sync reject information. Please try again later.'
                );
                return of<SyncRejectRecord[]>();
            }),
            finalize(() =>
                this.spinnerService.completeProcess(
                    'ReportService.searchSyncRejects.' + eloquaSyncRejectSearch.appinstanceExecutionId
                )
            )
        );
    }

    searchSalesforceErrors(
        interactionsUrl: string,
        salesforceErrorSearch: SalesforceErrorSearch,
        additionalHeaders: any = {}
    ): Observable<SalesforceError[]> {
        this.spinnerService.addProcess(
            'ReportService.searchSalesforceErrors.' + salesforceErrorSearch.appinstanceExecutionId,
            'Fetching your Salesforce error data.'
        );
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams()
        };

        this.addSearchParams(salesforceErrorSearch, customHttpOptions);

        return this.http.get<SalesforceError[]>(interactionsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve Salesforce error information. Please try again later.'
                );
                return of<SalesforceError[]>();
            }),
            finalize(() =>
                this.spinnerService.completeProcess(
                    'ReportService.searchSalesforceErrors.' + salesforceErrorSearch.appinstanceExecutionId
                )
            )
        );
    }

    searchGoogleInteractions(
        interactionsUrl: string,
        googleInteractionSearch: GoogleInteractionSearch,
        additionalHeaders: any = {}
    ): Observable<GoogleInteraction[]> {
        const process = 'ReportService.searchGoogleInteractions.' + googleInteractionSearch.appinstanceExecutionId;
        this.spinnerService.addProcess(process, 'Fetching your Google interaction data.');
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams()
        };

        this.addSearchParams(googleInteractionSearch, customHttpOptions);

        return this.http.get<GoogleInteraction[]>(interactionsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve Google interaction information. Please try again later.'
                );
                return of<GoogleInteraction[]>();
            }),
            finalize(() => this.spinnerService.completeProcess(process))
        );
    }

    searchGoogleErrors(
        errorsUrl: string,
        googleErrorSearch: GoogleErrorSearch,
        additionalHeaders: any = {}
    ): Observable<GoogleInteraction[]> {
        const process = 'ReportService.searchGoogleErrors.' + googleErrorSearch.appinstanceExecutionId;
        this.spinnerService.addProcess(process, 'Fetching your Google error data.');
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams()
        };

        this.addSearchParams(googleErrorSearch, customHttpOptions);

        return this.http.get<GoogleError[]>(errorsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve Google error information. Please try again later.'
                );
                return of<GoogleError[]>();
            }),
            finalize(() => this.spinnerService.completeProcess(process))
        );
    }

    searchNetsuiteInteractions(
        interactionsUrl: string,
        netsuiteInteractionSearch: NetsuiteInteractionSearch,
        additionalHeaders: any = {}
    ): Observable<NetsuiteInteraction[]> {
        const process = 'ReportService.searchNetsuiteInteractions.' + netsuiteInteractionSearch.appinstanceExecutionId;
        this.spinnerService.addProcess(process, 'Fetching your Netsuite interaction data.');
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams()
        };

        this.addSearchParams(netsuiteInteractionSearch, customHttpOptions);

        return this.http.get<NetsuiteInteraction[]>(interactionsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve Netsuite interaction information. Please try again later.'
                );
                return of<NetsuiteInteraction[]>();
            }),
            finalize(() => this.spinnerService.completeProcess(process))
        );
    }

    searchNetsuiteErrors(
        errorsUrl: string,
        netsuiteErrorSearch: NetsuiteErrorSearch,
        additionalHeaders: any = {}
    ): Observable<NetsuiteError[]> {
        const process = 'ReportService.searchNetsuiteErrors.' + netsuiteErrorSearch.appinstanceExecutionId;
        this.spinnerService.addProcess(process, 'Fetching your Netsuite error data.');
        const customHttpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams()
        };

        this.addSearchParams(netsuiteErrorSearch, customHttpOptions);

        return this.http.get<NetsuiteError[]>(errorsUrl, customHttpOptions).pipe(
            retry(0),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to retrieve Netsuite error information. Please try again later.'
                );
                return of<NetsuiteError[]>();
            }),
            finalize(() => this.spinnerService.completeProcess(process))
        );
    }

    static generateEloquaInteractionSearch(
        executionId: string,
        objectType: string,
        importId?: string,
        exportId?: string
    ): EloquaInteractionSearch {
        let newSearch: EloquaInteractionSearch = new EloquaInteractionSearch();
        newSearch.appinstanceExecutionId = executionId;
        newSearch.objectType = objectType;
        if (importId) {
            newSearch.eloquaImportId = importId;
        }
        if (exportId) {
            newSearch.eloquaExportId = exportId;
        }
        return newSearch;
    }

    static sortSyncLogs(syncLogs: SyncLogRecord[]): SyncLogRecord[] {
        let severitySortOrder = { warning: 1, rejected: 2, information: 3 };
        let sortedSyncLogRecords = syncLogs.sort(
            (a, b) =>
                severitySortOrder[a.severity] - severitySortOrder[b.severity] ||
                b.count - a.count ||
                a.statusCode.localeCompare(b.statusCode) ||
                a.severity.localeCompare(b.severity)
        );
        return sortedSyncLogRecords;
    }

    static transformIntoSyncLogData(syncRejectCounts: CountResponse[]): SyncLogRecord[] {
        let transformedData: SyncLogRecord[] = [];
        syncRejectCounts.forEach((countResponse) => {
            let newSyncLogRecord = new SyncLogRecord();
            newSyncLogRecord.count = countResponse.count;
            newSyncLogRecord.statusCode = countResponse.key;
            newSyncLogRecord.severity = 'rejected';
            transformedData.push(newSyncLogRecord);
        });
        return transformedData;
    }

    static generateAppStatsReportCountRequest(
        syncSearch: SyncSearch,
        eloquaSyncRejectsUrl: string
    ): AppStatsReportCountRequest {
        let newRequest = new AppStatsReportCountRequest();
        newRequest.resultsUrl = eloquaSyncRejectsUrl;
        newRequest.search = syncSearch;
        newRequest.groupBy = 'statusCode';
        return newRequest;
    }

    static generateNavigationData(
        syncLogs: SyncLogRecord[],
        syncSearch: SyncSearch,
        dateRange: DateRange,
        syncRejectRecords: SyncRejectRecord[]
    ): NavigationData {
        let navData = new NavigationData();
        navData.syncLogData = syncLogs;
        navData.syncId = syncSearch.eloquaSyncId;
        navData.executionId = syncSearch.appinstanceExecutionId;
        navData.dateRange = dateRange;
        navData.syncRejectData = syncRejectRecords;
        return navData;
    }

    static getCurrentStartTime(): string {
        let newStartTime = moment(new Date()).hour(0).minute(0).second(0).millisecond(0).format();
        return newStartTime;
    }

    static getCurrentEndTime(): string {
        let newEndTime = moment(new Date()).hour(23).minute(59).second(59).millisecond(999).format();
        return newEndTime;
    }

    private addSearchParams(search: AppStatsSearch, customHttpOptions: { headers; params }) {
        for (const [key, value] of Object.entries(search)) {
            if (key === 'fields') {
                this.addSearchField(value, customHttpOptions, 'select');
            } else if (key === 'groupByFields') {
                this.addSearchField(value, customHttpOptions, 'groupBy');
            } else {
                if (value !== undefined) {
                    customHttpOptions.params = customHttpOptions.params.set(key, value);
                }
            }
        }
    }

    private addSearchField(fields: AppStatsSearchField[], httpOptions: any, type: 'select' | 'groupBy') {
        let objectName;
        if (type === 'select') {
            objectName = 'fields';
        } else if (type === 'groupBy') {
            objectName = 'groupByFields';
        }
        fields.forEach((field, i) => {
            for (const [key, value] of Object.entries(field)) {
                if (value) {
                    httpOptions.params = httpOptions.params.set(objectName + '[' + i + '].' + key, value);
                }
            }
        });
    }
}
