import { Component } from '@angular/core';

@Component({
    selector: 'r1-appcloud-admin',
    template: ` <r1-home></r1-home> `,
    standalone: false
})
export class AppcloudAdminComponent {
    constructor() {}
}
