import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RowOptionButtons, RowOptionChoice } from '../single-field.model';

@Component({
    selector: 'r1-row-multiple-options',
    imports: [CommonModule, MatButtonModule, MatMenuModule, MatIconModule, BrowserAnimationsModule],
    template: `
        <button class="options" mat-button [matMenuTriggerFor]="optionsMenu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #optionsMenu="matMenu">
            <ng-container *ngFor="let option of rowOptions">
                <ng-container *ngIf="option.value !== 'delete'; else delete">
                    <button *ngIf="option.show" mat-menu-item (click)="onClickOption(option)" [attr.data-testid]="option.display" >
                        {{ option.display }}
                    </button>
                </ng-container>

                <ng-template #delete>
                    <button
                        *ngIf="index + 1 > totalRequiredRows"
                        mat-menu-item
                        (click)="onClickOption(option)"
                        [attr.data-testid]="option.display"
                    >
                        {{ option.display }}
                    </button>
                </ng-template>
            </ng-container>
        </mat-menu>
    `,
    styles: []
})
export class RowMultipleOptionsComponent {
    @Input()
    rowOptions!: RowOptionButtons[];

    @Output()
    clickOption: EventEmitter<RowOptionChoice> = new EventEmitter<RowOptionChoice>();

    @Input()
    index!: number;

    @Input()
    totalRequiredRows!: number;

    onClickOption(option: RowOptionButtons) {
        this.clickOption.emit({index: this.index, ...option});
    }
}
