import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SingleFieldService {
    constructor() {}

    /**
     * Sets proportionate percentage width of the row,
     * with consideration of the flex container's `gap` property's # pixels
     */
    setGroupingRows(fractionOfFullRowWidth: number, singleFieldWidth: number, flexGapSize: string) {
        const fraction = singleFieldWidth / fractionOfFullRowWidth;
        const fractionAsPercent = Math.floor(fraction * 100);
        return `calc(${fractionAsPercent}% - ${flexGapSize})`;
    }
}
