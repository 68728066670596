import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
    faHome,
    faUsers,
    faFunnelDollar,
    faChartArea,
    faBuilding,
    faUser,
    faShoppingCart,
    faBoxFull,
    faPlug,
    faTasks,
    faMegaphone,
    faChevronSquareLeft,
    faChevronSquareRight,
    faInfoSquare,
    faInfoCircle,
    faLock,
    faDatabase,
    faCrosshairs,
    faClock,
    faUserUnlock,
    faBookReader,
    faCommentAltDollar,
    faBars,
    faBadgeCheck,
    faSitemap,
    faInfo,
    faArrowToBottom
} from '@fortawesome/pro-solid-svg-icons';

// Icon library approach from
//    https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/icon-library.md#icon-library-approach
@NgModule({
    imports: [CommonModule]
})
export class FaIconsModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(
            faHome,
            faUsers,
            faInfoSquare,
            faInfoCircle,
            faFunnelDollar,
            faChartArea,
            faBuilding,
            faUser,
            faShoppingCart,
            faBoxFull,
            faPlug,
            faTasks,
            faMegaphone,
            faChevronSquareLeft,
            faChevronSquareRight,
            faLock,
            faDatabase,
            faCrosshairs,
            faCommentAltDollar,
            faClock,
            faUserUnlock,
            faBookReader,
            faBars,
            faBadgeCheck,
            faSitemap,
            faInfo,
            faArrowToBottom
        );
    }
}
