import { R1BrandingConstants } from './r1-branding.constants';
import { Breadcrumb } from '../components/breadcrumb-list/breadcrumb.model';

interface ReportDataElement {
    property: string;
    label: string;
    legendLabel?: string;
}

export class ReportingConstants {
    static Colors = class {
        static red: string = R1BrandingConstants.red;
        static orange: string = R1BrandingConstants.orange;
        static lightOrange: string = '#f3b88c';
        static brilliantBlue: string = R1BrandingConstants.brilliantBlue;
        static darkBlue: string = R1BrandingConstants.darkBlue;
        static lightBlue: string = R1BrandingConstants.lightBlue;
        static green: string = R1BrandingConstants.green;
    };

    static BreadCrumbs = class {
        static mainBreadcrumb: Breadcrumb = {
            title: 'Main Report',
            url: '/reporting/main'
        };
        static syncLogsBreadcrumb: Breadcrumb = {
            title: 'Sync Logs',
            url: '/reporting/sync-logs'
        };
        static syncRejectsBreadcrumb: Breadcrumb = {
            title: 'Sync Rejects',
            url: '/reporting/sync-rejects'
        };
        static googleErrorCountBreadCrumb: Breadcrumb = {
            title: 'Google Errors',
            url: '/reporting/google-error-counts'
        };
    
        static googleErrorDetailsBreadCrumb: Breadcrumb = {
            title: 'Google Error Details',
            url: '/reporting/google-error-details'
        };
        static responsysInteractionsBreadCrumb: Breadcrumb = {
            title: 'Responsys Interaction Details',
            url: '/reporting/responsys-interaction-details'
        }
    };
    static mainBreadcrumbArr: Breadcrumb[] = [ReportingConstants.BreadCrumbs.mainBreadcrumb];
    static syncLogsBreadcrumbArr: Breadcrumb[] = [
        ReportingConstants.BreadCrumbs.mainBreadcrumb,
        ReportingConstants.BreadCrumbs.syncLogsBreadcrumb
    ];
    static syncRejectsBreadcrumbArr: Breadcrumb[] = [
        ReportingConstants.BreadCrumbs.mainBreadcrumb,
        ReportingConstants.BreadCrumbs.syncRejectsBreadcrumb
    ];
    static googleErrorCountBreadcrumbArr: Breadcrumb[] = [
        ReportingConstants.BreadCrumbs.mainBreadcrumb,
        ReportingConstants.BreadCrumbs.googleErrorCountBreadCrumb
    ];
    static googleErrorDetailBreadcrumbArr: Breadcrumb[] = [
        ReportingConstants.BreadCrumbs.mainBreadcrumb,
        ReportingConstants.BreadCrumbs.googleErrorCountBreadCrumb,
        ReportingConstants.BreadCrumbs.googleErrorDetailsBreadCrumb
    ];
    static responsysInteractionsBreadcrumbArr: Breadcrumb[] = [
        ReportingConstants.BreadCrumbs.mainBreadcrumb,
        ReportingConstants.BreadCrumbs.responsysInteractionsBreadCrumb
    ];

    static mainUrl: string = ReportingConstants.BreadCrumbs.mainBreadcrumb.url;
    static syncLogsUrl: string = ReportingConstants.BreadCrumbs.syncLogsBreadcrumb.url;
    static syncRejectsUrl: string = ReportingConstants.BreadCrumbs.syncRejectsBreadcrumb.url;
    static googleErrorCountsUrl: string = ReportingConstants.BreadCrumbs.googleErrorCountBreadCrumb.url;
    static googleErrorDetailsUrl: string = ReportingConstants.BreadCrumbs.googleErrorDetailsBreadCrumb.url
    static responsysInteractionDetailsUrl: string = ReportingConstants.BreadCrumbs.responsysInteractionsBreadCrumb.url;

    static ReportData = class {
        static numRecordsSuccessful: ReportDataElement = {
            property: 'numRecordsSuccessful',
            label: 'Successful',
            legendLabel: 'Successful'
        };
        static numRecordsProcessed: ReportDataElement = {
            property: 'numRecordsProcessed',
            label: 'Processed',
            legendLabel: 'Processed'
        };
        static numRecordsCreated: ReportDataElement = {
            property: 'numRecordsCreated',
            label: 'Created',
            legendLabel: 'Created'
        };
        static numRecordsUpdated: ReportDataElement = {
            property: 'numRecordsUpdated',
            label: 'Updated',
            legendLabel: 'Updated'
        };
        static numRecordsRejected: ReportDataElement = {
            property: 'numRecordsRejected',
            label: 'Rejected',
            legendLabel: 'Rejected'
        };
        static numRecordsFailed: ReportDataElement = {
            property: 'numRecordsFailed',
            label: 'Failed',
            legendLabel: 'Failed'
        };
        static numRecordsErrored: ReportDataElement = {
            property: 'numRecordsErrored',
            label: 'Errored',
            legendLabel: 'Errored'
        };
        static numRecordsExported: ReportDataElement = {
            property: 'numRecordsExported',
            label: 'Exported',
            legendLabel: 'Exported'
        };
        static numRecordsImported: ReportDataElement = {
            property: 'numRecordsImported',
            label: 'Imported',
            legendLabel: 'Imported'
        };
        static numRecordsYes: ReportDataElement = {
            property: 'numRecordsYes',
            label: 'Yes',
            legendLabel: 'Yes'
        };
        static numRecordsNo: ReportDataElement = {
            property: 'numRecordsNo',
            label: 'No',
            legendLabel: 'No'
        };
        static numCdoRecordsExported: ReportDataElement = {
            property: 'numCdoRecordsExported',
            label: 'CDO Exported',
            legendLabel: 'CDO Exported'
        };
        static numCdoRecordsPersisted: ReportDataElement = {
            property: 'numCdoRecordsPersisted',
            label: 'CDO Saved',
            legendLabel: 'CDO Saved'
        };
        static numRecordsCloudContent: ReportDataElement = {
            property: 'numRecordsDynamicContent',
            label: 'Cloud Content',
            legendLabel: 'Cloud Content'
        };
        static numRecordsBlankContent: ReportDataElement = {
            property: 'numRecordsStaticContent',
            label: 'Blank Content',
            legendLabel: 'Blank Content'
        };

        static appinstanceExecutionId: ReportDataElement = {
            property: 'appinstanceExecutionId',
            label: 'App Instance Execution ID'
        };
        static eloquaExecutionId: ReportDataElement = {
            property: 'eloquaExecutionId',
            label: 'Eloqua Execution ID'
        };

        static eloquaExecutionIds: ReportDataElement = {
            property: 'eloquaExecutionIds',
            label: 'Eloqua Execution IDs'
        };
        static elapsedSeconds: ReportDataElement = {
            property: 'elapsedSeconds',
            label: 'Elapsed Seconds'
        };
        static importDetails: ReportDataElement = {
            property: 'importDetails',
            label: 'Notes'
        };
        static filepath: ReportDataElement = {
            property: 'filepath',
            label: 'File Path'
        };

        static numRowsWrittenToCsv: ReportDataElement = {
            property: 'numRowsWrittenToCsv',
            label: '# of Rows Written to CSV'
        };

        static errorDetails: ReportDataElement = {
            property: 'errorDetails',
            label: 'Error Details'
        };

        static numMatchingCdoRecords: ReportDataElement = {
            property: 'numMatchingCdoRecords',
            label: 'Record Count'
        };
        static landingPageUuid: ReportDataElement = {
            property: 'landingPageUuid',
            label: 'Landing Page Unique ID'
        };
        static eloquaUrl: ReportDataElement = {
            property: 'eloquaUrl',
            label: 'Eloqua Url'
        };
        static numLandingPagesCreated: ReportDataElement = {
            property: 'numLandingPagesCreated',
            label: 'Landing Pages Created'
        };
        static numConsentDenied: ReportDataElement = {
            property: 'numConsentDenied',
            label: 'Ad Consent Denied'
        }
    };
}
