import { Component, Input } from '@angular/core';
import { Account } from 'projects/internal/r1-component-library/src/lib/components/appcloud-login/appcloud-credentials/appcloud-credentials.model';

/**
 * Displays an individual App Cloud account.
 * Created with paginated App Cloud admin portal table of accounts in mind.
 */
@Component({
    selector: 'r1-view-account',
    templateUrl: './view-account.component.html',
    styleUrls: ['./view-account.component.scss'],
    standalone: false
})
export class ViewAccountComponent {
    @Input() account!: Account;
    constructor() {}
}
