<div class="date-picker-container">
    <button mat-stroked-button (click)="onPrevDay()">Prev</button>
    <form>
        <mat-form-field class="element-input">
            <span class="date-input-container">
                <input
                    [owlDateTime]="dt1"
                    [owlDateTimeTrigger]="dt1"
                    [placeholder]="placeholder"
                    (dateTimeChange)="onDateChange($event)"
                    [value]="selectedDate"
                    name="datepicker"
                    matInput
                    type="text"
                />
                <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
                <fa-icon [owlDateTimeTrigger]="dt1" icon="calendar-alt"></fa-icon>
            </span>
        </mat-form-field>
    </form>
    <button mat-stroked-button (click)="onNextDay()">Next</button>
</div>
