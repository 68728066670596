import { Component } from '@angular/core';
import { ManageAccountsService } from 'projects/appcloud-admin-portal/src/app/services/manage-accounts.service';
import { Account } from 'projects/internal/r1-component-library/src/lib/components/appcloud-login/appcloud-credentials/appcloud-credentials.model';

/**
 * Houses a serverside paginated table of App Cloud accounts, and a right-sidenav
 * which allows viewing and editing a single account.
 */
@Component({
    selector: 'r1-accounts-list',
    templateUrl: './accounts-list.component.html',
    styleUrls: ['./accounts-list.component.scss'],
    standalone: false
})
export class AccountsListComponent {
    constructor(private manageAccountsService: ManageAccountsService) {}

    sidenavOpen: boolean = false;
    selectedAccount: Account = null;
    editingSelectedAccount: boolean = false;

    closeSidenav() {
        this.sidenavOpen = false;
        this.selectedAccount = null;
        this.editingSelectedAccount = false;
    }

    toggleEditAccount() {
        this.editingSelectedAccount = !this.editingSelectedAccount;
    }

    toggleViewAccountInfo(selectedAccount: Account) {
        if (!this.selectedAccount) {
            // No account yet selected, open viewer to selected
            this.selectedAccount = selectedAccount;
            this.sidenavOpen = true;
        } else if (this.selectedAccount && this.selectedAccount !== selectedAccount) {
            // New account selected, change viewer to new account
            this.selectedAccount = selectedAccount;
            this.editingSelectedAccount = false;
        } else {
            // Same account, do nothing
            this.selectedAccount = selectedAccount;
        }
    }

    accountPatched(patchAccountValue: Account) {
        let { id, ...editedAccount } = patchAccountValue;
        this.manageAccountsService.patchAccount(id, editedAccount).subscribe((updatedAccount) => {
            this.manageAccountsService.saveAccountEdit();
            this.selectedAccount = updatedAccount;
        });
    }
}
